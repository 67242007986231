import React, { Fragment } from 'react';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import Img from 'gatsby-image';
import ModalWindow from '../components/modal/modal-window'
import { useModalWithData } from '../hooks/modal-hook'
import Seo from '../components/Seo';
import Boop from '../components/boop';
import { mq, colors, font } from '../consts/style';

import AnimatedNumber from  '../components/animated-numbers';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';

import '../style/react-accordion.css';
import {
  PageWrapper,
  PageInner,
  PageTitle,
  FocusText,
  Text,
  BgWrap,
  Banner,
  Grid2Col,
  Spacer,
 
  EquipeMembreTitle,

} from '../components/Elements';

const Grid2ColTeam = styled(Grid2Col)`
  column-gap:0;
  
`

const EquipeMembreTitleModal = styled(EquipeMembreTitle)`
 margin-top: 2.8rem;
  padding-left: 3rem;
 &:after {
    content:"";
    position:absolute;
    display:block;
    bottom:-1.2rem;
    width:20px;
    height:.3rem;
    background-color:${colors.yellow};
    }
`

const DescriptionEquipeModal = styled.p`
padding-left:3rem;
padding-right:3rem;
padding-bottom:3rem;
margin-top:2rem;
`

const CardEquipeWrapper = styled.div`
  cursor:pointer;
  display:flex;
  grid-gap:1rem;
  align-items: flex-start;
  justify-content:center; 
  width:100%;
  flex-wrap: wrap;
  ${mq.tabletSmall_up`
     flex-wrap: wrap;
   `}
`

const CardEquipe = styled.div`
   display:flex;
   flex-direction:column;
   align-items: center;
   justify-content:center; 
   /*width:100%;*/
   margin-top:3rem;
  /*${mq.tabletSmall_up`
      width:50%;
   `}*/
   text-align:center;
  p {
    line-height: 22px;
    letter-spacing:-1%;
    font-weight:400;
 
  }
`

const PhotoMembre = styled(Img)`
  border-radius: 4px;
  border-top:3px solid ${colors.yellow};
`
const NumbersWrapper = styled.div`
  align-self: center;
  display:grid;
  grid-template-columns: 1fr 1fr;
  align-items:center;
  justify-content:space-between;
  background:${colors.yellowLight};
  background: linear-gradient(90deg, rgba(241,239,212,1) 5%, rgba(246,234,203,1) 100%);
  border-radius: 4px;
`
const NumberLegend = styled.p`
  text-align:center;
  color:${colors.dark};
  ${font.text};
  margin-top:1rem;
  font-size:1.6rem;
  text-align:center;
`
const NumberWrapper = styled.div`
  display:flex;
  flex-direction:column;
  align-items:center;
  justify-content:space-between;
  padding: 3rem;
  color: ${colors.blue};
  font-size: 5rem;
  font-family: 'Kalam', cursive;
  font-weight:700;
`
const StyledAnimatedNumber = styled(AnimatedNumber)`
 
`

export const aProposPageQuery = graphql`
 query  aProposPageQuery($locale: String) {
   
    banner: file(relativePath: { eq: "B99I0117 © EDDY TERMINI.jpg" }) {
        childImageSharp {
          fluid(maxWidth:1820, quality:60) {
            ...GatsbyImageSharpFluid
          }
        }
      }

    page: datoCmsAProposPage(locale: {eq: $locale}) {
      titreHistoire
      sousTitreHistoire
      texteHistoire
      titreEquipe
      equipeIntro
      chiffresClefs {
        id
        chiffre
        legende
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      equipeMembres {
        id
        prenom
        titre
        description 
        photo {
          fixed(width: 264, forceBlurhash: false, imgixParams: { fm: "jpg",  auto: "compress",  fit: "crop",  w:"264" }) {
            ...GatsbyDatoCmsFixed
          }
          fluid(maxWidth:901,  imgixParams: { fm: "jpg",  auto: "compress",  fit: "crop",  w:"901", h:"600" }){
            ...GatsbyDatoCmsFluid
          }
        }
      }
      faq {
        id
        question
        reponse
      }
      photoEquipePleineSaison {
        fluid(maxWidth: 1200, forceBlurhash: false, imgixParams: { fm: "jpg",  auto: "compress" }) {
            ...GatsbyDatoCmsFluid
          }
          alt
      }
      
      titreSectionPourquoiNous
      illustrationPourquoiNous {
        fluid(maxWidth: 709, forceBlurhash: false) {
          ...GatsbyDatoCmsFluid
        }
      }
    
    }
  }
`;

const AProposPage = ({data}) => {
  
  const {
        modalOpen,
        selected,
        setSelected,
        setModalState
      } = useModalWithData()

  const {  titreHistoire, sousTitreHistoire, texteHistoire,  titreEquipe, equipeIntro, seoMetaTags, chiffresClefs, photoEquipePleineSaison, faq, titreSectionPourquoiNous, illustrationPourquoiNous } = data.page;
console.log (data.page.equipeMembres.length)
  return (
    <Fragment>
      <Seo meta={seoMetaTags} />
      <PageWrapper>
        <Banner height="205px">
          <Img fluid={data.banner.childImageSharp.fluid} />
        </Banner>
        <PageInner>
        <Grid2Col>
          <div>
              <PageTitle dangerouslySetInnerHTML={{ __html: titreHistoire }} />

              <FocusText dangerouslySetInnerHTML={{ __html: sousTitreHistoire }} />
              <Text dangerouslySetInnerHTML={{ __html: texteHistoire }} />

              <br/>
            </div>
            <NumbersWrapper>
            {chiffresClefs.map( chiffre => (

              <NumberWrapper key={chiffre.id}>
               <StyledAnimatedNumber numberToAnimate={chiffre.chiffre}/>
               <NumberLegend> {chiffre.legende}</NumberLegend>
              </NumberWrapper>
              )
            )}
          </NumbersWrapper> 
        </Grid2Col>
      </PageInner>
      <BgWrap curved color="#ECF2F2" >
        <PageInner>
          <PageTitle dangerouslySetInnerHTML={{ __html: titreEquipe }} />
          {equipeIntro && <div dangerouslySetInnerHTML={{ __html: equipeIntro }} />}
          {(data.page.equipeMembres.length > 0) &&  <>
          <ModalWindow  isActive={modalOpen}
          handleClose={() => setModalState(false)} 
            >     
            { (selected) &&
           
            <Grid2ColTeam>
             <Img fluid = {selected.membre.photo.fluid}/>
             <div>
                <EquipeMembreTitleModal>{selected.membre.prenom}</EquipeMembreTitleModal>
                <DescriptionEquipeModal  dangerouslySetInnerHTML={{ __html:selected.membre.description}}/>
            </div>
            </Grid2ColTeam>
            
            }
            </ModalWindow> 
            <CardEquipeWrapper>
             {data.page.equipeMembres.map(membre => (
              <Boop y={-5}  timing={300} key={membre.id} >
              <CardEquipe key={membre.id}  onClick={() => {
                      setSelected({membre})
                      setModalState(true)}}>
              
              <PhotoMembre fixed={membre.photo.fixed} />
                <EquipeMembreTitle>{membre.prenom}</EquipeMembreTitle>
                <p>
                  {membre.titre} 
                </p>

              </CardEquipe>
              </Boop>
            ))}
              </CardEquipeWrapper>
</>}
            <Spacer/>
            <div dangerouslySetInnerHTML={{ __html:photoEquipePleineSaison.alt}}/>
            <Img fluid = {photoEquipePleineSaison.fluid}/>
             <Spacer/>
          </PageInner>

        </BgWrap>
{ illustrationPourquoiNous && 
        <PageInner>
          <PageTitle dangerouslySetInnerHTML={{ __html:titreSectionPourquoiNous}}/>
          <Img fluid={illustrationPourquoiNous.fluid} style={{"maxWidth":"709px","textAlign":"center","margin":"0 auto"}}/>
        </PageInner>
}

        <PageInner id="faq">
          <PageTitle><span>FAQ</span></PageTitle>
           <Accordion>
            {faq.map( item => (
            <AccordionItem key={item.id}>
                <AccordionItemHeading>
                    <AccordionItemButton>
                        <div dangerouslySetInnerHTML={{ __html: item.question }} />
                    </AccordionItemButton>
                </AccordionItemHeading>
                <AccordionItemPanel>
                    <div dangerouslySetInnerHTML={{ __html: item.reponse }} />
                </AccordionItemPanel>
            </AccordionItem>
            )
            )}
          </Accordion>
        </PageInner>
       
      </PageWrapper>
    </Fragment>
  );
}
export default AProposPage
